import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../core/model/MenuItem';
import { GatewayService } from '../core/services/gateway.service';
import { IframeComponent } from '../iframe/iframe.component';
import { LocalStorageService } from '../core/services/local-storage.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  title = 'site-personas';
  public currentUrl : string;

  @ViewChild(IframeComponent) iframeComponent: IframeComponent; // Get the child component reference


  menu: MenuItem[] = [
  ];

  constructor(private router: Router,
    private storageService: LocalStorageService,
     private gatewayService: GatewayService) {}
  
  
  ngOnInit(): void {
    this.gatewayService.getSiteInfo().subscribe(result => {
      this.menu = result.menu;
    });
  }

  navigateToUrl(item: MenuItem) {
    item.expanded = !item.expanded;

    if(item.children == null || item.children.length == 0){
      this.currentUrl = item.url; 
      this.iframeComponent.urlChangeEvent(item.url);
      //let path = this.getPath(item);      
      this.router.navigate(['/app'], { queryParams: { id: item.id } });
    }
  }
  getPath(item: MenuItem): string {
    let path = item.name;
  
    let parentItem = this.findParent(this.menu, item);
  
    // Traverse up to the root and build the full path
    while (parentItem) {
      path = parentItem.name + '/' + path;
      parentItem = this.findParent(this.menu, parentItem);
    }
  
    return path;
  }


  findParent(menu: MenuItem[], child: MenuItem): MenuItem | null {
    for (let item of menu) {
      if (item.children && item.children.includes(child)) {
        return item;
      }
      if (item.children) {
        const parent = this.findParent(item.children, child);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  toggleMenu(item: MenuItem): void {
    item.expanded = !item.expanded;
  }

  logout(){ 
    this.storageService.removeToken();
    this.router.navigate(['/app']).then(() => {
      window.location.reload(); // Force a full reload after navigation
    });
  }



  getIconPath(iconName: string): string {
    const iconPaths = {
      'dashboard': 'M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z',
      'user': 'M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z',
      'product': 'M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5z'
    };
    return iconPaths[iconName] || '';
  }
}

