import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../core/services/local-storage.service';
import { MenuItem } from '../core/model/MenuItem';
import { GatewayService } from '../core/services/gateway.service';
@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
  
  public url: SafeResourceUrl;
  private counter = 0;
  
  menu: MenuItem[] = [
  ];

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private gatewayService: GatewayService,
    private storageService: LocalStorageService,
    private router: Router) {

   
  }


  listenForFallbackRoutingEvents() { }

  
  ngOnInit(): void {
    var token = this.storageService.getToken();   

    this.gatewayService.getSiteInfo().subscribe(result => {
      this.menu = result.menu;

      this.route.url.subscribe(urlSegments => {
              
        this.route.queryParams.subscribe(params => {
          var id = Number(params['id']);
          console.log(id);
          var item = this.findById(this.menu,id);
          
          const requestedUrl = `${item.url}/${token}`;
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(requestedUrl);
          
        });
  
        //const requestedUrl = `https://mic-apigateway.web.app/#/${token}`;
        //console.log(requestedUrl);
        //const requestedUrl = 'https://api.microservicios.co/admin/gateway/#/';
   
        //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(requestedUrl);
      });
  
      this.listenForFallbackRoutingEvents();

    });
  }

  public urlChangeEvent(url:string){
    var token = this.storageService.getToken();   
    const requestedUrl = `${url}/${token}`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(requestedUrl);
  }


  findById(menu: MenuItem[], id: number): MenuItem | null {
    
    for (const item of menu) {

      if (item.id === id) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        // Recursively search in children
        const found = this.findById(item.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  getUrlFromPath(path: string): string {
    // Split the path into segments
    const segments = path.split('/').filter(segment => segment);
  
    // Start searching from the root menu
    let currentItems = this.menu;
  
    for (const segment of segments) {
      const foundItem = currentItems.find(item => item.name === segment);
      if (foundItem) {
        // If the item has children, continue searching in the children
        if (foundItem.children && foundItem.children.length > 0) {
          currentItems = foundItem.children;
        } else {
          // If no children, return the URL of the found item
          return foundItem.url || '';
        }
      } else {
        // Path segment not found in current level
        return '';
      }
    }
  
    // Path not found
    return '';
  }
}