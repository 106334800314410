import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
  ) { }

  getSiteInfo(): Observable<any> {

      

    return this.http.get(environment.URL_API + '/portal/microservicios').pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }


}
