import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GatewayService } from './core/services/gateway.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  
  constructor(private router: Router, private gatewayService: GatewayService) {}
  
  
  ngOnInit(): void {
   
  }

}

