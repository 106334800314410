import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../core/model/MenuItem';
import { GatewayService } from '../core/services/gateway.service';
import { IframeComponent } from '../iframe/iframe.component';
import { LocalStorageService } from '../core/services/local-storage.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  title = 'site-personas';
  public currentUrl : string;

  @ViewChild(IframeComponent) iframeComponent: IframeComponent; // Get the child component reference


  menu: MenuItem[] = [
  ];

  constructor(private router: Router,
    private storageService: LocalStorageService,
     private gatewayService: GatewayService) {}
  
  
  ngOnInit(): void {
    this.gatewayService.getSiteInfo().subscribe(result => {
      this.menu = result.data.menu;
    });
  }

  navigateToUrl(item: MenuItem) {
    item.expanded = !item.expanded;

    if(item.children == null || item.children.length == 0){
      this.currentUrl = item.url; 
      this.iframeComponent.urlChangeEvent(item.url);
      //let path = this.getPath(item);      
      this.router.navigate(['/app'], { queryParams: { id: item.id } });
      this.loadWidgetScript();

    }
  }


  toggleMenu(item: MenuItem): void {
    item.expanded = !item.expanded;
  }

  logout(){ 
    this.storageService.removeToken();
    this.router.navigate(['/app']).then(() => {
      window.location.reload(); // Force a full reload after navigation
    });
  }



  getIconPath(iconName: string): string {
    const iconPaths = {
      'dashboard': 'M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z',
      'user': 'M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z',
      'product': 'M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5z'
    };
    return iconPaths[iconName] || '';
  }



  // Function to dynamically load the widget script from UNPKG
loadWidgetScript() {
  const script = document.createElement('script');
  script.src = 'https://unpkg.com/mic-sample-widget@1.0.3/my-widget.js'; // Replace with actual widget package URL
  script.async = true;

  
  script.onload = () => {
    console.log('Widget script loaded successfully');

    // Check if the widget function is available on window
    if (typeof window['MyWidget'] !== 'undefined' && window['MyWidget'].create) {


      const data = [
        ['ID', 'Name', 'Age', 'Location'],
        { id: 1, name: 'John Doe', age: 28, location: 'New York' },
        { id: 2, name: 'Jane Smith', age: 34, location: 'San Francisco' },
        { id: 3, name: 'Sam Green', age: 22, location: 'Chicago' }
      ];
  
      // Call the widget's createWidget function
      window['MyWidget'].create('widget','tailwind-widget', data);

      // Call the widget's create function
      //window['MyWidget'].create('widget', 'Hello, this is my widget!');
    } else {
      console.error('MyWidget.create is not available.');
    }
  };

  script.onerror = () => {
    console.error('Failed to load widget script');
  };

  document.body.appendChild(script);
}
}

